import { isPlainObject } from '@reduxjs/toolkit';
import { baseRestApiURL } from 'configs/appConfig';
import { ErrorBoundaryMessage } from 'features/components/pages/ErrorBoundary';
import { SchemaError, ValidationError } from 'interfaces/interfaces';
import { authentication } from 'store/store';

type Method = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
type FetcherResultType<EndpointReturnType> = Promise<
  SuccessResponse<EndpointReturnType> | ErrorResponse<ErrorBoundaryMessage>
>;

interface DiremoRequestInit<EndpointReturnType> extends Omit<RequestInit, 'body'> {
  method: Method;
  token?: string;
  body?: object;
  onSuccessCallback?: (response: Response) => FetcherResultType<EndpointReturnType>;
}

const defaultHeaders: HeadersInit = {
  'Content-Type': 'application/json',
};

const fetcher = <EndpointReturnType>(
  endpoint: string,
  requestInit: DiremoRequestInit<EndpointReturnType>,
  context: Context = 'rest'
): FetcherResultType<EndpointReturnType> => {
  const { method, body, onSuccessCallback, signal } = requestInit;
  const isFormData = body instanceof FormData;
  const headerData = isFormData ? {} : defaultHeaders;

  const headers: HeadersInit = {
    ...headerData,
    ...requestInit.headers,
    ...{ Authorization: authentication.bearerToken },
    credentials: 'same-origin',
  };

  return fetch(`${baseRestApiURL}${context}/${endpoint}`, {
    headers,
    mode: 'cors',
    method,
    signal,
    body: isFormData ? body : JSON.stringify(body),
  }).then(
    async (response) => {
      const responseBody = await response.clone().text();

      if (response.ok) {
        if (responseBody) {
          return onSuccessCallback
            ? onSuccessCallback(response)
            : successResponse((await response.json()) as EndpointReturnType);
        }

        return successResponse({} as EndpointReturnType);
      }

      if (response.status === 404) {
        const error: ErrorBoundaryMessage = {
          id: Date.now(),
          message: '404 - Resource not found',
          stack: `When making a request to ${baseRestApiURL}${context}/${endpoint}, the server responded that the resource was not found`,
        };
        throw new Error(JSON.stringify(error));
      }

      if (response.status === 401) {
        const error: ErrorBoundaryMessage = {
          id: Date.now(),
          message: '401 - Unauthorized',
          stack: `When making a request to ${baseRestApiURL}${context}/${endpoint}, the server responded that the request was unauthorized`,
        };
        throw new Error(JSON.stringify(error));
      }

      const result = responseBody ? await response.json() : {};

      if (isSystemError(result)) {
        const error: ErrorBoundaryMessage = {
          id: Date.now(),
          message: result.cause_sv ?? result.cause ?? result['exception class'],
          stack: result.stacktrace,
        };
        throw new Error(JSON.stringify(error));
      }

      const schemaError = result.error;

      if (isSchemaError(schemaError)) {
        const error: ErrorBoundaryMessage = {
          id: Date.now(),
          message: schemaError.description,
          stack: schemaError.errorcode,
        };
        throw new Error(JSON.stringify(error));
      }

      if (responseBody.length === 0) {
        const error: ErrorBoundaryMessage = {
          id: Date.now(),
          message: `Backend returned an error (code ${response.status}) but no body`,
          stack: '',
        };
        throw new Error(JSON.stringify(error));
      }

      const error: ErrorBoundaryMessage = {
        id: Date.now(),
        message: `${responseBody}`,
        stack: '',
      };
      throw new Error(JSON.stringify(error));
    },
    (err) => {
      const error: ErrorBoundaryMessage = {
        id: Date.now(),
        message: `Systemfel: ${err.message ?? 'Okänt fel'}`,
        stack: '',
        ignoreThisError: signal?.aborted === true,
      };

      // AbortError comes in when a fetch has been aborted
      if (err.name === 'AbortError')
        return {
          success: false,
          data: error,
        };

      if (err.message === 'NetworkError when attempting to fetch resource.')
        return {
          success: false,
          data: { ...error, ignoreThisError: true },
        };

      throw new Error(JSON.stringify(error));
    }
  );
};

export type FetcherMethod =
  | Lowercase<Method>
  | `view${'ExcelFile' | 'PdfFile' | 'ImageFile'}`
  | 'getPngImage'
  | 'downloadFile';
export type Context =
  | 'rest'
  | 'runtime'
  | 'repository'
  | 'identity'
  | 'form'
  | 'history'
  | 'domain';
export type FetcherFn = <EndpointReturnType>(
  endpoint: string,
  requestInit?: Omit<DiremoRequestInit<EndpointReturnType>, 'method'>,
  context?: Context
) => FetcherResultType<EndpointReturnType>;
type HttpRequest = Record<FetcherMethod, FetcherFn>;
type ViewFileCallback = (
  errorMessage?: string
) => (response: Response) => FetcherResultType<unknown>;

const getImageCallback: ViewFileCallback = (errorMessage) => async (response) => {
  try {
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    return successResponse({ fileUrl });
  } catch (err: any) {
    const error: ErrorBoundaryMessage = {
      id: Date.now(),
      message: errorMessage ?? err.message ?? 'Could not open file',
      stack: err,
    };
    return errorResponse(error);
  }
};

type DownloadFileFn = (headers: HeadersInit, errorMessage?: string) => FetcherFn;
const downloadFileCallback: ViewFileCallback = (errorMessage) => async (response) => {
  try {
    const blob = await response.blob();
    const contentDisposition = response.headers.get('Content-Disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let fileName: string;

    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    } else {
      fileName = 'file';
    }

    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(fileUrl);
    return successResponse({} as any);
  } catch (err: any) {
    const error: ErrorBoundaryMessage = {
      id: Date.now(),
      message: errorMessage ?? err.message ?? 'Could not download file',
      stack: err,
    };
    return errorResponse(error);
  }
};

const viewFileCallback: ViewFileCallback = (errorMessage) => async (response) => {
  try {
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    window.open(fileUrl, '_blank', 'noreferrer');
    return successResponse({} as any);
  } catch (err: any) {
    const error: ErrorBoundaryMessage = {
      id: Date.now(),
      message: errorMessage ?? err.message ?? 'Could not open file',
      stack: err,
    };
    return errorResponse(error);
  }
};
type ViewFileFn = (headers: HeadersInit, errorMessage?: string) => FetcherFn;
const viewFile: ViewFileFn = (headers, errorMessage) => (endpoint, requestInit, context) =>
  fetcher<any>(
    endpoint,
    {
      method: 'GET',
      headers,
      onSuccessCallback: viewFileCallback(errorMessage),
    },
    context
  );

const getImage: ViewFileFn = (headers, errorMessage) => (endpoint, requestInit, context) =>
  fetcher<any>(
    endpoint,
    {
      method: 'GET',
      headers,
      onSuccessCallback: getImageCallback(errorMessage),
    },
    context
  );

const downloadFile: DownloadFileFn = (headers, errorMessage) => (endpoint, requestInit, context) =>
  fetcher<any>(
    endpoint,
    {
      method: 'GET',
      headers,
      onSuccessCallback: downloadFileCallback(errorMessage),
    },
    context
  );

export const http: HttpRequest = {
  get: (endpoint, requestInit, context) =>
    fetcher(endpoint, { ...requestInit, method: 'GET' }, context),
  post: (endpoint, requestInit, context) =>
    fetcher(endpoint, { ...requestInit, method: 'POST' }, context),
  put: (endpoint, requestInit, context) =>
    fetcher(endpoint, { ...requestInit, method: 'PUT' }, context),
  patch: (endpoint, requestInit, context) =>
    fetcher(endpoint, { ...requestInit, method: 'PATCH' }, context),
  delete: (endpoint, requestInit, context) =>
    fetcher(endpoint, { ...requestInit, method: 'DELETE' }, context),
  viewPdfFile: viewFile({ 'Content-Type': 'application/pdf' }),
  viewExcelFile: viewFile({ 'Content-Type': 'application/vnd.ms-excel' }),
  viewImageFile: viewFile({ 'Content-Type': 'image/png' }),
  getPngImage: getImage({ 'Content-Type': 'image/png' }),
  downloadFile: downloadFile({}),
};

interface SuccessResponse<T> {
  success: true;
  data: T;
}

interface ErrorResponse<T> {
  success: false;
  data: T;
}

const successResponse = <T>(data: T): SuccessResponse<T> => {
  return {
    success: true,
    data,
  };
};

const errorResponse = <T>(data: T): ErrorResponse<T> => {
  return {
    success: false,
    data,
  };
};

interface SystemError extends ValidationError {
  stacktrace: string;
  'exception class': string;
}

export const isSuccessResponse = <T>(x: unknown): x is SuccessResponse<T> =>
  isPlainObject(x) && 'success' in x && x.success === true && 'data' in x;

export const isErrorResponse = <T>(x: unknown): x is ErrorResponse<T> =>
  isPlainObject(x) && 'success' in x && x.success === false && 'data' in x;

const isSystemError = (x: object): x is SystemError => isPlainObject(x) && 'cause' in x;

const isSchemaError = (x: object): x is SchemaError =>
  isPlainObject(x) && ('errorcode' in x || 'description' in x);
