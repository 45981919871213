const eventTypes = ['ON_RESIZE', 'ON_EXCEPTION'] as const;

export type EventType = (typeof eventTypes)[number];

/*eslint @typescript-eslint/no-explicit-any: ["off", { "ignoreRestArgs": true }]*/
type AnyFunctionArgs = any;
type AnyFunction = (...data: AnyFunctionArgs) => void;
type EventsType = Record<EventType, AnyFunction[]>;

export const EventDispatcher = (function () {
  const events = eventTypes.reduce<Partial<EventsType>>((acc, curr) => {
    acc[curr] = [];
    return acc;
  }, {}) as EventsType;

  /**
   * Dispatches an event if it exists
   * Example usage: EventDispatcher.dispatch('namechanged', { name: 'John' });
   * @param {*} event to dispatch
   * @param {*} data to send with the event
   */
  const dispatch = (event: EventType, ...data: AnyFunctionArgs) => {
    events[event].forEach((ev) => ev(...data));
  };

  /**
   * Subscribes to an event
   * Example usage: EventDispatcher.subscribe('namechanged', function(data) { alert(data.name); });
   * @param {*} event to subscribe to
   * @param {*} callback to call on dispatch
   */
  const subscribe = (event: EventType, callback: AnyFunction) => {
    events[event] = [...events[event], callback];
  };

  /**
   * Unsubscribes a function to an event.
   * @param {*} event to unsubscribe to
   * @param {*} callback to remove from event
   */
  const unsubscribe = (event: EventType, callback: AnyFunction) => {
    if (events[event].length === 1) {
      events[event] = [];
    } else {
      events[event] = events[event].filter((fn: AnyFunction) => fn !== callback);
    }
  };

  return {
    dispatch,
    subscribe,
    unsubscribe,
  };
})();
