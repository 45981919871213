import { AnyAction } from '@reduxjs/toolkit';
import { Metric } from 'web-vitals';
import {
  DurationMilliSeconds,
  DurationMinutes,
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from './types';
import { KeyboardEvent } from 'react';
import labels from 'configs/labels';

/**
 * Returns a new object that only contains records with specified keys
 */
export const pick = <T extends object, K extends keyof T>(source: T, ...keys: K[]): Pick<T, K> => {
  return keys.reduce<Partial<T>>((acc, key) => {
    acc[key] = source[key];
    return acc;
  }, {}) as Pick<T, K>;
};

/**
 * Returns a new object that does not contain records with specified keys
 */
export const omit = <T extends object, K extends keyof T>(source: T, ...keys: K[]): Omit<T, K> => {
  return ObjectEntries(source).reduce<Partial<T>>((acc, [key, val]) => {
    if (!keys.includes(key as K)) acc[key] = val;
    return acc;
  }, {}) as Omit<T, K>;
};

/**
 * Returns a new object (shallow copy) with specified replacements
 */
export const modify = <T extends object>(source: T, replacement: Partial<T>) => ({
  ...source,
  ...replacement,
});

/**
 * A type safe alias to Object.keys
 */
export const ObjectKeys = <T extends object>(obj: T) => Object.keys(obj) as (keyof T)[];

/**
 * A type safe alias to Object.values
 */
export const ObjectValues = <T extends object>(obj: T) => Object.values(obj) as T[keyof T][];

/**
 * A type safe alias to Object.entries
 */
export const ObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as [keyof T, T[keyof T]][];

export const isDefined = <T>(x: T | undefined | null): x is T => x != null;
export const blankWhenUndefined = (x: string | undefined): string => {
  return isDefined(x) ? x : '';
};
export const isString = (x: any): x is string => typeof x === 'string';
export const isNumber = (x: any): x is number => typeof x === 'number' && !isNaN(x);
export const isBoolean = (x: any): x is boolean => typeof x === 'boolean';
export const isSymbol = (x: any): x is symbol => typeof x === 'symbol';
export const isDate = (x: any): x is Date => x instanceof Date && !isNaN(x.getTime());
export const isArray = <T>(x: any): x is Array<T> => Array.isArray(x);
export const round = (num: number, decimals: number) => Number(Number(num).toFixed(decimals));
/**
 * Returns a string on the form YYYY-MM-DD
 * @param date Date or a string that can be parsed to a Date
 * @param locale
 */
export const getIsoDate = (date: Date | string | number[], locale = 'sv-SE') => {
  if (!date) return '';
  if (isArray(date)) {
    const [year, month, day] = date;
    return Intl.DateTimeFormat(locale).format(new Date(year, month - 1, day));
  }
  if (isString(date)) {
    try {
      const parsedDate = Date.parse(date);
      return Intl.DateTimeFormat(locale).format(parsedDate);
    } catch {
      throw new Error(`Could not parse '${date}' to a date`);
    }
  }
  return Intl.DateTimeFormat(locale).format(date);
};

export const getTodayIsoDate = (locale = 'sv-SE') => {
  return getIsoDate(new Date(), locale);
};

export const getIsoDateTime = (date: Date | string) => {
  if (!date) return '';
  if (isString(date)) {
    date = new Date(Date.parse(date));
  }
  const datePart = date.toLocaleDateString(new Intl.Locale('se'));
  const timePart = date.toLocaleTimeString();
  const [hour, minute] = timePart.split(':');

  return `${datePart} ${hour}:${minute}`;
};

export const getIsoDateTimeSec = (date: Date) => {
  return `${getIsoDateTime(date)}:00`;
};

export const getIsoYearMonth = (date: Date) => {
  if (isString(date)) {
    date = new Date(Date.parse(date));
  }
  const datePart = date.toLocaleDateString(new Intl.Locale('se'));

  return datePart.substring(0, 7);
};

export const parseIsoDate = (s: string): Date | undefined => {
  if (!s) return undefined;
  if (s.length < 10) return undefined;
  const [y, m, d] = s.split('-');
  const separators = /[ T]/;
  const [day, time] = d.split(separators);
  if (time) {
    const [hour, minute, second] = time.split(':');
    if (second) return new Date(+y, +m - 1, +day, +hour, +minute, +second);
    return new Date(+y, +m - 1, +day, +hour, +minute);
  }
  return new Date(+y, +m - 1, +day);
};

export const getRelativeTimeFormat = (date: Date, navigatorLanguage: string) => {
  const now = Date.now();
  const minutesDiff = (date.getTime() - now) / 1000 / 60;
  const rtf = new Intl.RelativeTimeFormat(navigatorLanguage, {
    numeric: 'auto',
  });
  const [diff, unit] = getTimeUnit(minutesDiff);

  return rtf.format(diff, unit);
};

const getTimeUnit = (minutes: DurationMinutes): [number, Intl.RelativeTimeFormatUnit] => {
  if (Math.abs(minutes) < 1) return [Math.round(minutes * 60), 'second'];

  if (Math.abs(minutes) < 60) return [Math.round(minutes), 'minute'];

  const hours = Math.round(minutes / 60);
  if (Math.abs(hours) < 24) return [hours, 'hour'];

  const days = Math.round(minutes / 60 / 24);
  if (Math.abs(days) < 31) return [days, 'day'];

  const months = Math.round(minutes / 60 / 24 / 30.42);
  if (Math.abs(months) < 12) return [months, 'month'];

  const years = Math.round(minutes / 60 / 24 / 365);
  return [years, 'year'];
};

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor = 200) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export function delay(time: DurationMilliSeconds) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Sorts a list of strings. Can handle if strings starts with a number
 * Usage: someListOfString.sort(sortStringsContainingNumbers)
 */
export const sortStringsContainingNumbers = (a: string, b: string) =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });

/**
 * Returns a string on the form key1=value1&key2=value2...
 * @param paramPairs An object with key-value pairs, ex: { key1:'value1', key2=22, key3: undefined }. If value is undefined, it will not be included in the returned string.
 */
export const getUrlSearchParams = (paramPairs: Record<string, string | number | undefined>) => {
  const urlSearchParams = new URLSearchParams();

  ObjectEntries(paramPairs).forEach(([name, value]) => {
    if (value !== undefined) urlSearchParams.append(name, String(value));
  });

  return urlSearchParams.toString();
};

export const addToBrowserHistory = (route: string) => {
  if (history.state?.route !== route) {
    history.pushState({ route, idx: history.length }, '', '/');
    history.replaceState({ route, idx: history.length }, '', route);
  }
};

export const isEnterKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'Enter';
export const isSpaceKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === ' ';
export const isTabKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'Tab';
export const isEscapeKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'Escape';
export const isArrowDownKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'ArrowDown';
export const isArrowUpKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'ArrowUp';
export const isArrowLeftKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'ArrowLeft';
export const isArrowRightKey = (ev: KeyboardEvent<HTMLElement>) => ev.key === 'ArrowRight';

// eslint-disable-next-line no-console
export const consoleLog = (payload: string | object | Metric) => console.log(payload);

// Redux slice related helpers
export const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};

export const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};

export function getCondensed(description: string, maxlength: number): string {
  if (description == null) return '';
  const text = description.replace('<br>', ' ').replace(/<[^>]*>/g, '');
  if (text.length < maxlength) {
    return text;
  }
  return text.substring(0, maxlength) + '...';
}

export function validatePersonalNumber(personalNumber: string): boolean {
  // Remove any non-digit characters from the personal number
  const cleanedNumber = personalNumber.replace(/\D/g, '');

  // Check if the cleaned number has the correct length
  if (cleanedNumber.length !== 12) {
    return false;
  }

  // Check the date part of the personal number
  const month = parseInt(cleanedNumber.substring(4, 6), 10);
  const day = parseInt(cleanedNumber.substring(6, 8), 10);

  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return false;
  }

  const ssn = cleanedNumber
    .replace(/\D/g, '') // strip out all but digits
    .split('') // convert string to array
    .reverse() // reverse order for Luhn
    .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

  if (checkIntervalForZeroValues(ssn, 0, 3)) return true;

  const sum = ssn
    // convert to number
    .map(function (n) {
      return Number(n);
    })
    // perform arithmetic and return sum
    .reduce(function (previous, current, index) {
      // multiply every other number with two
      if (index % 2) current *= 2;
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) current -= 9;
      // sum it up
      return previous + current;
    });

  // sum must be divisible by 10
  return 0 === sum % 10;
}

function checkIntervalForZeroValues(arr: string[], startIdx: number, endIdx: number): boolean {
  const interval = arr.slice(startIdx, endIdx + 1);
  return interval.every((value) => value === '0');
}

export const getFirstSearchParamValue = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.values().next().value as string) ?? '';
};

export type VisibilityState = 'visible' | 'hidden' | null;
const visibilityStateKey = 'visibilityState';
export const getIsNewTab = () => {
  // The premise is that a new tab opens up without being in focus. That will result in the visibilityState being hidden.
  const visibilityState = sessionStorage.getItem(visibilityStateKey) as VisibilityState;

  if (visibilityState === null) {
    // Save the visibilityState in sessionStorage to be able to determine if the tab is new or not in later app loading (perhaps when comming back from external identity provider).
    sessionStorage.setItem(visibilityStateKey, document.visibilityState);

    // Clear the visibilityState after 3 seconds so a new tab can be refreshed without being considered a new tab
    setTimeout(clearVisibilityState, 3000);
  }

  return (
    visibilityState === 'hidden' ||
    (visibilityState !== 'visible' && document.visibilityState === 'hidden')
  );
};

export const clearVisibilityState = () => {
  // Cleanup the visibilityState when not needed anymore
  const visibilityState = sessionStorage.getItem(visibilityStateKey) as VisibilityState;

  // Only do a cleanup if the visibilityState is hidden
  if (visibilityState === 'hidden') sessionStorage.removeItem(visibilityStateKey);
};

export function removeLastCharacterIfS(input: string): string {
  if (input.endsWith('s')) {
    return input.slice(0, -1);
  }
  return input;
}

export function addPluralS(input: string): string {
  if (input.endsWith('s')) {
    return input + 'es';
  }
  return input + 's';
}

export function removeBeforeIncluding(word: string, source: string | undefined): string {
  if (!source) return '';
  const position = source.indexOf(word);
  return position >= 0 ? source.slice(position + word.length) : source;
}

export function removeAfter(word: string, source: string | undefined): string {
  if (!source) return '';
  const strParts = source.split(word);
  if (strParts.length > 1) {
    return strParts[0].trim() + word;
  }
  return '';
}

export function getLabel(input: string): string {
  return (labels as { [key: string]: any })[input] || input;
}

export function boolToString(value: boolean) {
  return value ? 'true' : 'false';
}
