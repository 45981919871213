import { isPlainObject } from '@reduxjs/toolkit';
import { consoleLog } from 'helpers/utils';
import { KeycloakProps } from 'slices/navigation/navigationSlice';

const isAuthProps = (x: unknown): x is KeycloakProps => {
  return (
    isPlainObject(x) &&
    'isAuthenticated' in x &&
    'profile' in x &&
    'token' in x &&
    'tokenParsed' in x
  );
};

const REQUESTING_AUTH_PROPS = 'requesting-auth-props';

// Used to broadcast authentication information to new tab/window
export default class AuthPropsBroadcast {
  private authChannel = new BroadcastChannel('diremo-app-broadcast-channel');
  public keycloakProps: KeycloakProps | undefined;

  constructor(
    isNewTab: boolean,
    private readonly setKeycloakProps: (keycloakProps: KeycloakProps) => void
  ) {
    this.registerBroadcastChannelListener();

    if (isNewTab) {
      this.authChannel.postMessage(REQUESTING_AUTH_PROPS);
    }
  }

  updateKeycloakProps(keycloakProps: KeycloakProps) {
    this.keycloakProps = keycloakProps;
  }

  get isAuthenticated() {
    return this.keycloakProps?.isAuthenticated ?? false;
  }

  private registerBroadcastChannelListener() {
    this.authChannel.onmessage = (ev: MessageEvent<string | KeycloakProps>) => {
      if (ev.data === REQUESTING_AUTH_PROPS) {
        this.authChannel.postMessage(this.keycloakProps);
        return;
      }

      if (isAuthProps(ev.data)) {
        this.setKeycloakProps(ev.data);
      }
    };

    this.authChannel.onmessageerror = (ev: MessageEvent) => {
      consoleLog(
        `BroadcastChannel (${this.authChannel.name}) received an error message from ${ev.origin}: ${ev.data}`
      );
    };
  }
}
