import { ObjectKeys } from 'helpers/utils';
import { SidebarMenu } from 'interfaces/interfaces';
import { RootState } from 'store/store';
import { SelectedSidebarMenu } from './navigationSlice';

export interface AuthResponse {
  Authorization: string;
}

export interface AuthenticatorRequest {
  username: string;
  password: string;
  auth?: string;
}

export interface SidebarMenuExtended extends SidebarMenu {
  isPageLink: boolean;
  isHiddenInMenu?: boolean;
  submenu: SidebarMenuExtended[];
}

export type SidebarMenuOpenState = Record<number, boolean>;
export type BreadCrumb = { id: number; route?: string; isPageLink?: boolean; text: string };

export const getRouteParts = (route: string) => route.split('/').filter((part) => part.length);

export const syncOpenState = (
  currentRoute: string,
  sidebarMenuOpenState: SidebarMenuOpenState,
  sidebarMenuRaw?: SidebarMenu[]
) => {
  if (currentRoute === '/') {
    return ObjectKeys(sidebarMenuOpenState).reduce<SidebarMenuOpenState>((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }

  if (sidebarMenuRaw) return flatten(sidebarMenuRaw, getRouteParts(currentRoute));

  return sidebarMenuOpenState;
};

export const flatten = (sidebarMenu: SidebarMenu[], routeParts: string[]) => {
  return sidebarMenu.reduce<SidebarMenuOpenState>((acc, menu) => {
    const isOpen = menu.route === routeParts[0];
    if (isOpen) routeParts.shift();

    acc[menu.id] = isOpen;

    if (menu.submenu.length) acc = { ...acc, ...flatten(menu.submenu, routeParts) };

    return acc;
  }, {});
};

export const extendRoutes = (
  sidebarMenu: (SidebarMenu | SidebarMenuExtended)[],
  parentRoute?: string
) => {
  const getNewRoute = (menuRoute: string, parentRoute?: string) => {
    const padWithSlash = !menuRoute.endsWith('/') && !menuRoute.endsWith('.html');

    if (parentRoute)
      return padWithSlash ? `${parentRoute}${menuRoute}/` : `${parentRoute}${menuRoute}`;

    return padWithSlash ? `${menuRoute}/` : menuRoute;
  };

  return sidebarMenu.reduce<SidebarMenuExtended[]>((acc, menu) => {
    // Make sure all routes contains the complete path of all ancestors
    const route = getNewRoute(menu.route, parentRoute).replace('${token}/', '');

    acc.push({
      id: menu.id,
      description: menu.description,
      route,
      page: menu.page,
      isPageLink: menu.submenu.length === 0,
      isHiddenInMenu: (menu as SidebarMenuExtended).isHiddenInMenu,
      submenu: menu.submenu.length ? extendRoutes(menu.submenu, route) : [],
    });

    return acc;
  }, []);
};

const buildBreadcrumb = (sidebarMenu: SidebarMenu[], currentRoute: string) => {
  return sidebarMenu.reduce<BreadCrumb[]>((acc, menu) => {
    if (currentRoute.startsWith(menu.route)) {
      const route = menu.submenu.length ? undefined : menu.route;

      if (route !== '/' && route != 'search/')
        acc.push({ id: menu.id, route, text: menu.description });

      if (menu.submenu.length) acc.push(...buildBreadcrumb(menu.submenu, currentRoute));
    }
    return acc;
  }, []);
};

export const getBreadcrumb = (state: RootState) => {
  const { selectedSidebarMenu, sidebarMenu } = state.navigation;

  return buildBreadcrumb(sidebarMenu, selectedSidebarMenu.currentWithPageLink.route);
};

export const findMenu = (
  sidebarMenu: SidebarMenuExtended[],
  route: string
): SidebarMenuExtended | undefined => {
  if (sidebarMenu.length === 0) return;

  // eslint-disable-next-line
  return sidebarMenu.reduce<SidebarMenuExtended | undefined>((acc, menuItem) => {
    if (route === menuItem.route) return menuItem;
    if (route.startsWith(menuItem.route)) return findMenu(menuItem.submenu, route);

    return acc;
  }, undefined);
};

export const findPage = (
  sidebarMenu: SidebarMenuExtended[],
  page: string
): SidebarMenuExtended | undefined => {
  if (sidebarMenu.length === 0) return;

  // eslint-disable-next-line
  return sidebarMenu.reduce<SidebarMenuExtended | undefined>((acc, menuItem) => {
    if (page === menuItem.route) return menuItem;
    if (menuItem.route.endsWith(page)) return findMenu(menuItem.submenu, page);

    return acc;
  }, undefined);
};

export const getSelectedSidebarMenu = (
  sidebarMenu: SidebarMenuExtended[],
  route: string,
  selectedSidebarMenuBefore: SelectedSidebarMenu
): SelectedSidebarMenu => {
  if (sidebarMenu.length === 0) return selectedSidebarMenuBefore;

  const currentSidebarMenu: SidebarMenuExtended = findMenu(sidebarMenu, route) ?? {
    id: -99,
    isPageLink: true,
    isHiddenInMenu: true,
    route,
    page: '',
    description: '',
    submenu: [],
  };

  return {
    current: currentSidebarMenu,
    currentWithPageLink: currentSidebarMenu?.isPageLink
      ? currentSidebarMenu
      : selectedSidebarMenuBefore.currentWithPageLink,
    previous: selectedSidebarMenuBefore.current.isPageLink
      ? selectedSidebarMenuBefore.current
      : selectedSidebarMenuBefore.previous,
  };
};
