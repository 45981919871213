import { LoadableComponent } from '@loadable/component';
import { isDefinedRoute, isOldRoute, oldRoutes } from 'configs/routeConfig';
import { Suspense } from 'react';
import { getLoadablePageFromRoute, pageLoader } from 'loadables/resourceLoaders';
import { selectCurrentRoute, selectCurrentSidebarMenu } from 'slices/navigation/navigationSlice';
import { useAppSelector } from 'store/store';
import { ModalSpinner } from './components/organisms/modal/ModalVariants';
import { SidebarMenuExtended } from 'slices/navigation/navigationSliceHelpers';
import { useAppLink } from './components/atoms/AppLink';
import { getFirstSearchParamValue } from 'helpers/utils';
import pages from 'loadables/pages';

export default function Router() {
  const appLink = useAppLink();
  const currentRoute = useAppSelector(selectCurrentRoute);
  const adjustedRoute = oldRoutes[currentRoute] ?? currentRoute;
  const searchValue = getFirstSearchParamValue();
  const newRoute = searchValue ? `${adjustedRoute}${searchValue}` : undefined;
  const { currentWithPageLink, current } = useAppSelector(selectCurrentSidebarMenu);
  const routeToUse = getRouteToUse(currentRoute, adjustedRoute, current, currentWithPageLink);
  const pageToUse = getPageToUse(currentWithPageLink);

  if (newRoute && newRoute !== currentRoute && isOldRoute(currentRoute)) {
    // An old url was requested. Redirect to corresponding new url
    appLink(newRoute);
  }

  const PageFromRouter = pageToUse || getLoadablePageFromRoute(newRoute ?? routeToUse);

  return <SuspensedPage PageFromRouter={PageFromRouter} />;
}

interface SuspensedPageProps {
  pageName?: 'LoginPage' | 'MainPage';
  PageFromRouter?: LoadableComponent<unknown>;
  SuspenseFallback?: React.ReactNode;
}

export const SuspensedPage = ({
  pageName,
  PageFromRouter,
  SuspenseFallback,
}: SuspensedPageProps) => {
  const Page = PageFromRouter ?? pageLoader(pageName ?? 'NotFound');
  return (
    <Suspense fallback={SuspenseFallback ?? <ModalSpinner showSpinner={true} />}>
      <Page />
    </Suspense>
  );
};

const getRouteToUse = (
  currentRoute: string,
  adjustedRoute: string,
  current: SidebarMenuExtended,
  currentWithPageLink: SidebarMenuExtended
) => {
  if (currentRoute !== adjustedRoute) return adjustedRoute;

  if (currentRoute === '') return '/';

  if (current.route === currentWithPageLink.route && !isDefinedRoute(adjustedRoute))
    return adjustedRoute;

  return currentWithPageLink.route;
};

const getPageToUse = (currentWithPageLink: SidebarMenuExtended) => {
  if (!currentWithPageLink.page || currentWithPageLink.page.length < 1) return undefined;
  const pageToUse = currentWithPageLink.page as keyof typeof pages;
  return pageLoader(pageToUse);
};
