import { clearVisibilityState, pick } from 'helpers/utils';
import { KeycloakProps } from 'slices/navigation/navigationSlice';
import { StoreDispatch } from 'store/store';
import AuthPropsBroadcast from './AuthPropsBroadcast';
import KC from './KC';

export default class Authentication {
  private readonly authPropsBroadcast: AuthPropsBroadcast;
  private kc: KC | undefined;
  private keycloakProps: KeycloakProps | undefined;

  constructor(private readonly isNewTab: boolean, private readonly dispatch: StoreDispatch) {
    const setKeycloakProps = this.setKeycloakProps.bind(this);
    this.authPropsBroadcast = new AuthPropsBroadcast(isNewTab, setKeycloakProps);
    this.kc = isNewTab ? undefined : new KC(setKeycloakProps, dispatch);

    this.kc?.init();
  }

  setKeycloakProps(keycloakProps: KeycloakProps) {
    if (this.isNewTab) {
      this.kc = new KC(
        this.setKeycloakProps.bind(this),
        this.dispatch,
        pick(keycloakProps, 'refreshToken')
      );
    } else {
      this.authPropsBroadcast.updateKeycloakProps(keycloakProps);
    }

    this.keycloakProps = keycloakProps;
    clearVisibilityState();
  }

  get token() {
    return this.kc?.token ?? '';
  }

  get bearerToken() {
    return this.kc?.token ? `Bearer ${this.kc.token}` : '';
  }

  get profile() {
    return this.kc?.profile;
  }

  get username() {
    return this.kc?.profile?.username ?? '';
  }

  logout() {
    this.kc?.logout();
  }
}

export const emptyAuthentication = {
  token: '',
  bearerToken: '',
  profile: undefined,
  username: '',
  // eslint-disable-next-line
  logout: () => {},
};
