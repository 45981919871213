import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceBaseState, SliceStatus } from 'helpers/types';
import { RootState } from 'store/store';

const initialState: SliceBaseState = {
  status: 'idle',
};

const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<SliceStatus>) => {
      state.status = action.payload;
    },
    resetAppStatus: (state) => {
      state.status = 'idle';
    },
  },
});

export const { setAppStatus, resetAppStatus } = appStatusSlice.actions;

export default appStatusSlice.reducer;

export const selectAppStatus = (state: RootState) => state.appStatus.status;
export const selectIsAppBusy = (state: RootState) => state.appStatus.status === 'loading';
