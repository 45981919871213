import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { getIsNewTab } from 'helpers/utils';
import Authentication, { emptyAuthentication } from 'models/Authentication';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import HttpFetcherCache from '../models/HttpFetcherCache';
import appStatusReducer from '../slices/appStatusSlice';
import diremoTablePropsReducer from '../slices/diremoTableProps/diremoTablePropsSlice';
import genericReducer from '../slices/genericSlice';
import navigationReducer, { getSidebarMenuAsync } from '../slices/navigation/navigationSlice';
import userDetailsReducer, { fetchUserDetailsAsync } from '../slices/userDetailsSlice';
import userRightsReducer, { fetchUserRightsAsync } from '../slices/userRightsSlice';

const isNewTab = getIsNewTab();

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    userRights: userRightsReducer,
    userDetails: userDetailsReducer,
    appStatus: appStatusReducer,
    generic: genericReducer,
    diremoTableProps: diremoTablePropsReducer,
  },
});

export type StoreDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<StoreDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Skip AuthPropsBroadcast for jest testing
export const authentication = window.BroadcastChannel
  ? new Authentication(isNewTab, store.dispatch)
  : emptyAuthentication;

export const httpFetcherCache = new HttpFetcherCache(store.dispatch);

export const initReduxState = () => {
  store.dispatch(getSidebarMenuAsync());
  store.dispatch(fetchUserRightsAsync());
  store.dispatch(fetchUserDetailsAsync());
};
