import { useEffect, useState } from 'react';

let timerId: ReturnType<typeof setTimeout>;
const defaultWaitTime = 200;

interface Props {
  show: boolean;
  children: React.ReactNode;
  waitTime?: number;
}

export const DebouncedComponent = (props: Props) => {
  const [show, setShow] = useState(props.show);

  const { children, waitTime } = props;

  useEffect(() => {
    if (props.show !== undefined) {
      if (timerId) clearTimeout(timerId);

      timerId = setTimeout(() => setShow(props.show), waitTime ?? defaultWaitTime);
    }
  }, [props.show, waitTime]);

  return show ? <>{children}</> : null;
};
