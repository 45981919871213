import { IS_DEVELOPMENT_ENV, IS_PRODUCTION_ENV, IS_TEST_ENV, USE_LOCAL_BE } from './EnvConfig';

const devBaseRestApiURL = USE_LOCAL_BE
  ? 'http://localhost:8090/redimo/'
  : 'https://diremoutv.afbostader.se/redimo/';
const prodBaseRestApiURL = 'https://diremo.afbostader.se/redimo/';
const testBaseRestApiURL = 'https://diremotest.afbostader.se/redimo/';
const labBaseRestApiURL = '/redimo/';
export const baseRestApiURL = IS_PRODUCTION_ENV
  ? prodBaseRestApiURL
  : IS_TEST_ENV
  ? testBaseRestApiURL
  : IS_DEVELOPMENT_ENV
  ? devBaseRestApiURL
  : labBaseRestApiURL;
export const HTMLEditorApiKey = 'auolhv0aeh882fj8twntv4kbcgh328qauwjx9u8mrfzizwjz';
export const LogrocketLoggingEnabled = false;
export const requiredKeycloakRoles = ['diremouser'];
