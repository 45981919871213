import { ValueOf } from './types';

export const mediaQueries = {
  bpMin361: '(min-width: 361px)',
  bpMin401: '(min-width: 401px)',
  bpMin481: '(min-width: 481px)',
  bpMin601: '(min-width: 601px)',
  bpMin721: '(min-width: 721px)',
  bpMin991: '(min-width: 991px)',
  bpMin1025: '(min-width: 1025px)',
  bpMin1281: '(min-width: 1281px)',
  bpMin1441: '(min-width: 1441px)',
  bpMin1921: '(min-width: 1921px)',
  bpMin2561: '(min-width: 2561px)',

  bpMax360: '(max-width: 360px)',
  bpMax400: '(max-width: 400px)',
  bpMax480: '(max-width: 480px)',
  bpMax600: '(max-width: 600px)',
  bpMax720: '(max-width: 720px)',
  bpMax960: '(max-width: 990px)',
  bpMax1024: '(max-width: 1024px)',
  bpMax1280: '(max-width: 1280px)',
  bpMax1440: '(max-width: 1440px)',
  bpMax1920: '(max-width: 1920px)',
  bpMax2560: '(max-width: 2560px)',
} as const;

export type MediaQuery = ValueOf<typeof mediaQueries>;
