import { definedRoutes, Route } from 'configs/routeConfig';
import pages from './pages';
import { ObjectKeys } from 'helpers/utils';

export type PageName = keyof typeof pages;
export type PageLoaderReturnType = ReturnType<typeof pageLoader>;

export const pageLoader = <N extends keyof typeof pages>(pageName?: N) => {
  return pageName ? pages[pageName] : pages.NotFound;
};

const registerRouteKeys = ObjectKeys(definedRoutes).filter((r) => r.startsWith('register'));
const todolistKeys = ObjectKeys(definedRoutes).filter((r) => r.startsWith('todolist'));

export const getLoadablePageFromRoute = (route: string) => {
  const pageToLoad = getLoadablePage(route);

  return pageToLoad ? pageLoader(pageToLoad) : pageLoader('NotFound');
};

const matchRegisterRoutes = (route: string) => {
  const foundRoute = registerRouteKeys.find((definedRoute) => route.startsWith(definedRoute));

  return foundRoute ? definedRoutes[foundRoute] : undefined;
};

const matchTodolistRoutes = (route: string) => {
  const foundRoute = todolistKeys.find((definedRoute) => route.startsWith(definedRoute));

  return foundRoute ? definedRoutes[foundRoute] : undefined;
};

export const getLoadablePage = (route: string) => {
  let foundRoute = route.startsWith('register/') ? matchRegisterRoutes(route) : undefined;

  if (foundRoute) return foundRoute;

  foundRoute = route.startsWith('todolist/') ? matchTodolistRoutes(route) : undefined;

  if (foundRoute) return foundRoute;

  return definedRoutes[route as Route];
};
