import { useIsLargeDevice } from 'helpers/hooks/useIsLargeDevice';
import { useEffect } from 'react';
import { SuspensedPage } from './features/Router';
import {
  selectIsAuthenticated,
  selectIsAuthorized,
  setCurrentRouteFromHistory,
} from './slices/navigation/navigationSlice';
import { useAppDispatch, useAppSelector } from './store/store';

function App() {
  const dispatch = useAppDispatch();
  const isLargeDevice = useIsLargeDevice();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAuthorized = useAppSelector(selectIsAuthorized);

  useEffect(() => {
    const getPayload = () => ({
      route: location.pathname.substring(1),
      isLargeDevice,
    });
    const popstateListener = () => dispatch(setCurrentRouteFromHistory(getPayload()));

    window.addEventListener('popstate', popstateListener);

    return () => window.removeEventListener('popstate', popstateListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthenticated || isAuthorized === false) {
    return <SuspensedPage pageName="LoginPage" />;
  }

  return <SuspensedPage pageName="MainPage" />;
}

export default App;
