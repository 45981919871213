import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './faIcon.css';

export type FAIconName = keyof Omit<typeof solidIcons, 'prefix' | 'fas'>;

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  iconName: FAIconName;
}

export function AfbFAIcon({ onClick, ...rest }: Props) {
  const style = {
    paddingTop: '3px',
    paddingInlineEnd: '3px',
    color: '#007dc3',
    cursor: '',
  };
  if (onClick) style.cursor = 'pointer';
  return <FAIcon size={'sm'} style={style} onClick={onClick} {...rest} />;
}

export function FACloseIcon({ size, style, onClick }: Omit<Props, 'iconName'>) {
  return (
    <FAIcon
      className="diremo-icon-close"
      iconName="faWindowClose"
      size={size ?? 'xl'}
      style={style}
      onClick={onClick}
    />
  );
}

export function FASpinnerIcon({ size, color, style }: Omit<Props, 'iconName'>) {
  return (
    <FAIcon
      iconName="faSpinner"
      spin={true}
      color={color ?? 'white'}
      size={size ?? '4x'}
      style={style ?? { position: 'relative', top: 30 }}
    />
  );
}

export default function FAIcon({ iconName, ...rest }: Props) {
  return <FontAwesomeIcon icon={solidIcons[iconName]} {...rest} />;
}
