import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SliceBaseState } from 'helpers/types';
import { http } from './fetcher';
import { RootState } from 'store/store';
import { isArray } from 'helpers/utils';

type UserRights = Record<string, boolean>;
interface UserRightsState extends SliceBaseState {
  userRights: UserRights;
}

const initialState: UserRightsState = {
  status: 'idle',
  userRights: {},
};

export const fetchUserRightsAsync = createAsyncThunk('userRights/fetch', async () => {
  return await http.get<string[]>('user/permissions');
});

const userRightsSlice = createSlice({
  name: 'userRights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRightsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserRightsAsync.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload.success && isArray(action.payload.data)) {
          state.userRights = action.payload.data.reduce<UserRights>((acc, key) => {
            acc[key] = true;
            return acc;
          }, {});
        }
      })
      .addCase(fetchUserRightsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default userRightsSlice.reducer;

export const selectUserRights =
  (state: RootState) =>
  (...userRightNames: string[]) => {
    return userRightNames.reduce<UserRights>((acc, key) => {
      acc[key] = state.userRights.userRights[key] ?? false;
      return acc;
    }, {});
  };

export const selectUserRightsFactory =
  (state: RootState) =>
  (...userRightNames: string[]) => {
    const userRights = selectUserRights(state)(...userRightNames);

    return userRightsFactory(userRights);
  };

const userRightsFactory = (userRights: UserRights) => (userRight: string) => userRights[userRight];
