import { PageName } from 'loadables/resourceLoaders';

type LoadablePage = Exclude<PageName, 'MainPage' | 'LoginPage' | 'NotFound'>;
export type Route = `${string}/`;

export const definedRoutes: Record<Route, LoadablePage | undefined> = {
  '/': 'Home',
  'todolist/': 'TodoList',
  'register/product/': 'Products',
  'register/reservation/proposalstore/': 'ProposalStore',
  'register/inspection/': 'Inspection',
  'register/maintobject/maintenanceobjects/': 'MaintenanceObjects',
  'register/maintobject/registermeasures/': 'RegisterMeasures',
  'register/customer/': 'Customers',
  'register/contract/terminate/': 'Terminate',
  'register/contract/residentiallimit/': 'TerminateResidentialLimit',
  'register/contract/terminatestore/': 'TerminateStore',
  /* todo remove services/* routes when phased out */
  'services/terminatedhousing/': 'TerminatedHousing',
  'services/stores/terminatedstores/': 'TerminatedStores',
  'services/confirmterminations/': 'ConfirmTerminations',
  'services/stores/confirmterminationsstores/': 'ConfirmTerminationsStores',
  'services/stores/publishedstores/': 'PublishedStores',
  'services/stores/proposalstores/': 'ProposalStores',
  'services/stores/proposalstoresanswer/': 'ProposalStoresAnswer',
  'services/stores/signcontractstores/': 'SignContractStores',
  'services/todolist/': 'TodoList',
  'services/offlineinspectionlist/': 'OfflineInspectionsList',
  'services/certificates/': 'Certificates',
  'services/residentiallimit/': 'ResidentialLimit',
  'services/accesslist/': 'AccessList',
  'services/labels/': 'Labels',
  'services/timelimitcontract/': 'TimeLimitContracts',
  'services/kraftringen/': 'Kraftringen',
  'services/lottery/': 'Lottery',
  'services/queuetime/': 'QueueTime',
  'services/substitutes/': 'Substitutes',
  'rental/terminatedhousing/': 'TerminatedHousing',
  'stores/terminatedstores/': 'TerminatedStores',
  'rental/confirmterminations/': 'ConfirmTerminations',
  'stores/confirmterminationsstores/': 'ConfirmTerminationsStores',
  'stores/publishedstores/': 'PublishedStores',
  'stores/proposalstores/': 'ProposalStores',
  'stores/proposalstoresanswer/': 'ProposalStoresAnswer',
  'stores/signcontractstores/': 'SignContractStores',
  'offlineinspectionlist/': 'OfflineInspectionsList',
  'rental/certificates/': 'Certificates',
  'rental/residentiallimit/': 'ResidentialLimit',
  'accesslist/': 'AccessList',
  'rental/labels/': 'Labels',
  'rental/timelimitcontract/': 'TimeLimitContracts',
  'rental/kraftringen/': 'Kraftringen',
  'rental/lottery/': 'Lottery',
  'substitutes/': 'Substitutes',
  'communication/message/': 'Message',
  'communication/statistics/': 'Statistics',
  'project/selectproducts/': 'SelectProducts',
  'project/project/': 'DomainForm',
  'project/projectphase/': 'DomainForm',
  'project/productproject/': 'DomainForm',
  'project/access/': 'Access',
  'project/accessrequest/': 'DomainForm',
  'other/changepassword/': 'NewPassword',
  'other/documentation/': 'Documentation',
  'other/systemlog/': 'SystemLog',
  'other/info/': 'Info',
  'other/download/products/': 'ProductsExcel',
  'other/download/customers/': 'CustomersExcel',
  'other/download/maintobjects/': 'MaintObjectsExcel',
  'other/download/stores/': 'StoresExcel',
  'other/download/productkeys/': 'ProductKeysExcel',
  'other/download/details/': 'DetailsExcel',
  'other/download/aptus/': 'AptusExcel',
  'test/': 'TestPage',
  'systemadmin/users/user/': 'DomainForm',
  'systemadmin/users/userrole/': 'DomainForm',
  'systemadmin/users/useruserrole/': 'DomainForm',
  'systemadmin/users/permissionuserrole/': 'DomainForm',
  'systemadmin/users/permission/': 'DomainForm',
  'systemadmin/users/permissiondiremoservice/': 'DomainForm',
  'systemadmin/users/diremoservice/': 'DomainForm',
  'systemadmin/users/employee/': 'DomainForm',
  'systemadmin/users/supplier/': 'DomainForm',
  'systemadmin/users/employeerole/': 'DomainForm',
  'systemadmin/users/employeeemployeerole/': 'DomainForm',
  'systemadmin/users/orgunit/': 'DomainForm',
  'systemadmin/register/customers/customer/': 'DomainForm',
  'systemadmin/register/customers/customeraddress/': 'DomainForm',
  'systemadmin/register/customers/customercontact/': 'DomainForm',
  'systemadmin/register/customers/customerrestriction/': 'DomainForm',
  'systemadmin/register/customers/customercheck/': 'DomainForm',
  'systemadmin/register/customers/sublet/': 'DomainForm',
  'systemadmin/register/customers/exemption/': 'DomainForm',
  'systemadmin/register/contracts/contract/': 'DomainForm',
  'systemadmin/register/contracts/contractrent/': 'DomainForm',
  'systemadmin/register/contracts/contractcondition/': 'DomainForm',
  'systemadmin/register/products/product/': 'DomainForm',
  'systemadmin/register/products/areaproduct/': 'DomainForm',
  'systemadmin/register/products/productmaintobject/': 'DomainForm',
  'systemadmin/register/products/location/': 'DomainForm',
  'systemadmin/register/products/productdescription/': 'DomainForm',
  'systemadmin/register/products/productvariant/': 'DomainForm',
  'systemadmin/register/products/productsize/': 'DomainForm',
  'systemadmin/register/products/areasize/': 'DomainForm',
  'systemadmin/register/products/rent/': 'DomainForm',
  'systemadmin/register/products/rentalrestriction/': 'DomainForm',
  'systemadmin/register/products/productproductcondition/': 'DomainForm',
  'systemadmin/register/maintobjects/maintobjectstree/': 'DomainTreeView',
  'systemadmin/register/maintobjects/maintobject/': 'DomainForm',
  'systemadmin/register/maintobjects/areamaintobject/': 'DomainForm',
  'systemadmin/register/maintobjects/buildingspace/': 'DomainForm',
  'systemadmin/register/maintobjects/detail/': 'DomainForm',
  'systemadmin/register/maintobjects/equipment/': 'DomainForm',
  'systemadmin/register/maintobjects/measure/': 'DomainForm',
  'systemadmin/register/maintobjects/measuredescription/': 'DomainForm',
  'systemadmin/register/maintobjects/measuredescriptionassociation/': 'DomainForm',
  'systemadmin/register/maintobjects/accountingactivity/': 'DomainForm',
  'systemadmin/register/maintobjects/device/': 'DomainForm',
  'systemadmin/register/maintobjects/inspection/': 'DomainForm',
  'systemadmin/register/maintobjects/inspectiondetail/': 'DomainForm',
  'systemadmin/register/maintobjects/inspectioncharge/': 'DomainForm',
  'systemadmin/register/buildingparts/maintobjectstree/': 'DomainTreeView',
  'systemadmin/register/buildingparts/buildingpart/': 'DomainForm',
  'systemadmin/register/buildingparts/buildingparttype/': 'DomainForm',
  'systemadmin/register/buildingparts/component/': 'DomainForm',
  'systemadmin/register/buildingparts/componenttype/': 'DomainForm',
  'systemadmin/register/buildingparts/measure/': 'DomainForm',
  'systemadmin/register/buildingparts/measuredescription/': 'DomainForm',
  'systemadmin/register/buildingparts/accountingactivity/': 'DomainForm',
  'systemadmin/register/errands/errand/': 'DomainForm',
  'systemadmin/register/errands/activity/': 'DomainForm',
  'systemadmin/register/properties/property/': 'DomainForm',
  'systemadmin/register/properties/building/': 'DomainForm',
  'systemadmin/register/properties/areabuilding/': 'DomainForm',
  'systemadmin/register/areas/area/': 'DomainForm',
  'systemadmin/register/areas/areaemployee/': 'DomainForm',
  'systemadmin/register/accounting/accountingreport/': 'DomainForm',
  'systemadmin/register/accounting/accountdetail/': 'DomainForm',
  'systemadmin/register/accounting/customerinvoice/': 'DomainForm',
  'systemadmin/register/accounting/customerinvoicerow/': 'DomainForm',
  'systemadmin/register/accounting/customerinvoicereport/': 'DomainForm',
  'systemadmin/register/accounting/billingreport/': 'DomainForm',
  'systemadmin/register/accounting/customerpayment/': 'DomainForm',
  'systemadmin/register/accounting/customerpaymentreport/': 'DomainForm',
  'systemadmin/register/accounting/voucher/': 'DomainForm',
  'systemadmin/register/accounting/ledger/': 'DomainForm',
  'systemadmin/documentation/': 'DomainForm',
  'parametersettings/buildingspacetype/': 'DomainForm',
  'parametersettings/detailtype/': 'DomainForm',
  'parametersettings/descriptiongroup/': 'DomainForm',
  'parametersettings/descriptionalternative/': 'DomainForm',
  'parametersettings/productcondition/': 'DomainForm',
  'parametersettings/exemptionperiod/': 'DomainForm',
  'parametersettings/exemptionreason/': 'DomainForm',
  'parametersettings/rentpart/': 'DomainForm',
  'parametersettings/pricelist/': 'DomainForm',
  'parametersettings/maintobjectdescription/': 'DomainForm',
  'parametersettings/scheduledprocess/': 'DomainForm',
  'parametersettings/template/': 'DomainForm',
  'parametersettings/taskattribute/': 'DomainForm',
  'parametersettings/systemparameter/': 'DomainForm',
};

// Old urls mapping to new defined routes (above)
export const oldRoutes: Record<string, Route> = {
  'tjanster/besiktningar/besiktning/': 'register/inspection/',
  'tjanster/objekt/produkt/': 'register/product/',
  'tjanster/objekt/fastighetsobjekt/': 'register/maintobject/maintenanceobjects/',
  'tjanster/objekt/kund/': 'register/customer/',
};

export const isOldRoute = (route: string) => !!oldRoutes[route];

export const isDefinedRoute = (route: string): route is Route => !!definedRoutes[route as Route];
