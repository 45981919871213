import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceBaseState } from 'helpers/types';
import { addToBrowserHistory } from 'helpers/utils';
import { SidebarMenu } from 'interfaces/interfaces';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { httpFetcherCache, authentication, RootState } from 'store/store';
import { http } from '../fetcher';
import {
  extendRoutes,
  findMenu,
  flatten,
  getBreadcrumb,
  getRouteParts,
  getSelectedSidebarMenu,
  SidebarMenuExtended,
  SidebarMenuOpenState,
  syncOpenState,
} from './navigationSliceHelpers';
import { requiredKeycloakRoles } from 'configs/appConfig';

export interface SelectedSidebarMenu {
  current: SidebarMenuExtended;
  currentWithPageLink: SidebarMenuExtended;
  previous: SidebarMenuExtended;
}

export interface KeycloakProps {
  isAuthenticated: boolean;
  profile?: KeycloakProfile;
  token?: string;
  refreshToken?: string;
  idToken?: string;
  tokenParsed?: KeycloakTokenParsed;
}

export interface NavigationState extends SliceBaseState, KeycloakProps {
  isAuthenticated: boolean;
  isAuthenticationError: boolean;
  sidebarMenuRaw: SidebarMenu[];
  sidebarMenu: SidebarMenuExtended[];
  sidebarMenuOpenState: SidebarMenuOpenState;
  selectedSidebarMenu: SelectedSidebarMenu;
  currentRoute: string;
  isHamburgerMenuActive: boolean;
  isOffline: boolean;
}

interface RoutePayload {
  route: string;
  isLargeDevice: boolean;
}

const homeSideMenu: SidebarMenuExtended = {
  id: 0,
  isPageLink: true,
  isHiddenInMenu: true,
  route: '/',
  page: '',
  description: 'Hem',
  submenu: [],
};
const testPageSideMenu: SidebarMenuExtended = {
  id: -1,
  isPageLink: true,
  isHiddenInMenu: true,
  route: 'test/',
  page: '',
  description: 'Test page',
  submenu: [],
};

const initialState: NavigationState = {
  status: 'idle',
  isAuthenticated: false,
  isAuthenticationError: false,
  sidebarMenuRaw: [],
  sidebarMenu: [],
  sidebarMenuOpenState: {},
  selectedSidebarMenu: {
    current: homeSideMenu,
    currentWithPageLink: homeSideMenu,
    previous: homeSideMenu,
  },
  currentRoute: location.pathname.length > 1 ? location.pathname.substring(1) : location.pathname,
  isHamburgerMenuActive: false,
  isOffline: sessionStorage.getItem('offline') === 'true',
};

export const getSidebarMenuAsync = createAsyncThunk('sidebarMenu/fetch', async () => {
  const token = authentication.bearerToken;
  httpFetcherCache.setToken(token);
  const response = await http.get<SidebarMenu[]>('user/services', { token });

  return response ?? [];
});

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<KeycloakProps>) => {
      const { isAuthenticated, profile, token, tokenParsed } = action.payload;

      if (token && profile) {
        state.isAuthenticated = isAuthenticated;
        httpFetcherCache.setToken(token);
        state.profile = profile;
        state.token = token;
        state.tokenParsed = tokenParsed;
      }
    },
    logOut: (state) => {
      authentication.logout();
      state.profile = undefined;
      state.token = undefined;
      state.tokenParsed = undefined;
      state.isAuthenticated = false;
    },
    authenticationFailed: (state) => {
      state.isAuthenticationError = true;
      state.profile = undefined;
      state.token = undefined;
      state.tokenParsed = undefined;
      state.isAuthenticated = false;
    },
    toggleMenuOpenState: (state, action: PayloadAction<number>) => {
      const menuId = action.payload;
      state.sidebarMenuOpenState[menuId] = !state.sidebarMenuOpenState[menuId];
    },
    setCurrentRoute: (state, action: PayloadAction<RoutePayload>) => {
      const { route, isLargeDevice } = action.payload;
      state.currentRoute = route.replace(/^\//, '');

      const selectedSidebarMenu = getSelectedSidebarMenu(
        state.sidebarMenu,
        route,
        state.selectedSidebarMenu
      );

      state.selectedSidebarMenu = selectedSidebarMenu;
      state.sidebarMenuOpenState = syncOpenState(route, state.sidebarMenuOpenState);

      if (!isLargeDevice && state.isHamburgerMenuActive && selectedSidebarMenu.current.isPageLink)
        state.isHamburgerMenuActive = false;
    },
    setCurrentRouteFromHistory: (state, action: PayloadAction<RoutePayload>) => {
      const { route, isLargeDevice } = action.payload;
      let currentRoute = route || history.state;
      currentRoute = state.isOffline ? 'services/offlineinspectionlist/' : currentRoute;
      state.currentRoute = currentRoute;
      state.selectedSidebarMenu = getSelectedSidebarMenu(
        state.sidebarMenu,
        currentRoute,
        state.selectedSidebarMenu
      );
      state.sidebarMenuOpenState = syncOpenState(
        currentRoute,
        state.sidebarMenuOpenState,
        state.sidebarMenuRaw
      );
      if (!isLargeDevice) state.isHamburgerMenuActive = false;
    },
    toggleIsHamburgerMenuActive: (state) => {
      state.isHamburgerMenuActive = !state.isHamburgerMenuActive;
    },
    setIsHamburgerMenuActive: (state, action: PayloadAction<boolean>) => {
      state.isHamburgerMenuActive = action.payload;
    },
    toggleOffline: (state) => {
      state.isOffline = !state.isOffline;
      sessionStorage.setItem('offline', state.isOffline ? 'true' : 'false');
      if (state.isHamburgerMenuActive && state.isOffline) {
        state.isHamburgerMenuActive = false;
      }
    },
    setOffline: (state, action: PayloadAction<boolean>) => {
      if (state.isHamburgerMenuActive && action.payload) {
        state.isHamburgerMenuActive = false;
      }
      state.isOffline = action.payload;
      sessionStorage.setItem('offline', state.isOffline ? 'true' : 'false');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSidebarMenuAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSidebarMenuAsync.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload.success) {
          const routeParts = getRouteParts(state.currentRoute);
          const additionalMenuItems: SidebarMenu[] = [homeSideMenu, testPageSideMenu];
          const sidebarMenuRaw = additionalMenuItems.concat(action.payload.data);

          state.sidebarMenuRaw = sidebarMenuRaw;
          state.sidebarMenu = extendRoutes(sidebarMenuRaw);
          state.sidebarMenuOpenState = flatten(sidebarMenuRaw, routeParts);
          state.selectedSidebarMenu = getSelectedSidebarMenu(
            state.sidebarMenu,
            state.currentRoute,
            state.selectedSidebarMenu
          );

          addToBrowserHistory(state.currentRoute);
        }
      })
      .addCase(getSidebarMenuAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  logIn,
  logOut,
  authenticationFailed,
  toggleMenuOpenState,
  setCurrentRoute,
  setCurrentRouteFromHistory,
  toggleIsHamburgerMenuActive,
  setIsHamburgerMenuActive,
  toggleOffline,
  setOffline,
} = navigationSlice.actions;

export default navigationSlice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.navigation.isAuthenticated;
export const selectIsAuthorized = (state: RootState) => {
  const { realm_access } = state.navigation.tokenParsed ?? {};
  return realm_access
    ? requiredKeycloakRoles.every((role) => realm_access.roles.includes(role))
    : undefined;
};
export const selectIsAuthenticationError = (state: RootState) =>
  state.navigation.isAuthenticationError;
export const selectUserName = (state: RootState) => state.navigation.profile?.username ?? '';
export const selectName = (state: RootState) => {
  const profile = state.navigation.profile;
  if (profile === undefined) return '';

  return `${profile.firstName} ${profile.lastName}`;
};

export const selectFirstName = (state: RootState) => {
  const profile = state.navigation.profile;
  if (profile === undefined) return '';

  return profile.firstName;
};
export const selectToken = (state: RootState) => state.navigation.tokenParsed;
export const selectProfile = (state: RootState) => state.navigation.profile;
export const selectMenuNavigation = (state: RootState) => ({
  sidebarMenu: state.navigation.sidebarMenu.filter((menu) => !menu.isHiddenInMenu),
  sidebarMenuOpenState: state.navigation.sidebarMenuOpenState,
});
export const selectCurrentRoute = (state: RootState) => state.navigation.currentRoute;
export const selectCurrentSidebarMenu = (state: RootState) => state.navigation.selectedSidebarMenu;
export const selectRegisterRoute = (state: RootState) => {
  if (!state.navigation.currentRoute.startsWith('register/')) return state.navigation.currentRoute;

  const routeParts = getRouteParts(state.navigation.currentRoute);
  routeParts.shift();

  return routeParts.join('/');
};
export const selectOldServiceRoute = (state: RootState) => {
  if (!state.navigation.currentRoute.startsWith('tjanster/')) return state.navigation.currentRoute;
  const routeParts = getRouteParts(state.navigation.currentRoute);
  routeParts.shift();
  return routeParts.join('/');
};

export const selectTodoListRoute = (state: RootState) => {
  if (!state.navigation.currentRoute.startsWith('todolist/')) return state.navigation.currentRoute;
  const routeParts = getRouteParts(state.navigation.currentRoute);
  routeParts.shift();
  return routeParts.join('/');
};

export const selectQueryParameters = () => {
  return new URLSearchParams(window.location.search).toString();
};

export const selectQueryParametersAsObject = () => {
  return new URLSearchParams(window.location.search);
};

export const selectLastSegment = (state: RootState) =>
  state.navigation.currentRoute.split('/').pop();

export const selectBreadcrumb = (state: RootState) => getBreadcrumb(state);
export const selectIsHamburgerMenuActive = (state: RootState) =>
  state.navigation.isHamburgerMenuActive;

export const selectIsOffline = (state: RootState) => state.navigation.isOffline;

export const selectHeader = (state: RootState) => {
  const currentSidebarMenu = findMenu(state.navigation.sidebarMenu, state.navigation.currentRoute);
  if (currentSidebarMenu === undefined || currentSidebarMenu.route === '/') return undefined;

  const { currentWithPageLink } = state.navigation.selectedSidebarMenu;
  if (currentWithPageLink.route === '/') return undefined;

  return currentWithPageLink?.isPageLink ? currentWithPageLink?.description ?? '' : '';
};

export const selectSidebarMenu = (state: RootState) => {
  return state.navigation.sidebarMenu;
};
