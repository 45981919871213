import Labels from './labels';

export const PRODUCTION_ENV = 'PRODUCTION_ENV';
export const DEVELOPMENT_ENV = 'DEVELOPMENT_ENV';
export const TEST_ENV = 'TEST_ENV';
export const LAB_ENV = 'LAB_ENV';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT === 'production'
    ? PRODUCTION_ENV
    : process.env.REACT_APP_ENVIRONMENT === 'test'
    ? TEST_ENV
    : process.env.REACT_APP_ENVIRONMENT === 'develop'
    ? DEVELOPMENT_ENV
    : LAB_ENV
  : process.env.NODE_ENV === 'production'
  ? PRODUCTION_ENV
  : DEVELOPMENT_ENV;
export const IS_PRODUCTION_ENV = ENVIRONMENT === PRODUCTION_ENV;
export const IS_DEVELOPMENT_ENV = ENVIRONMENT === DEVELOPMENT_ENV;
export const IS_TEST_ENV = ENVIRONMENT === TEST_ENV;
export const IS_LAB_ENV = ENVIRONMENT === LAB_ENV;
export const USE_LOCAL_BE = process.env.REACT_APP_USE_LOCAL_BE === 'true';

export function getReactAppEnvironment() {
  return IS_PRODUCTION_ENV
    ? Labels.environment.prod
    : IS_TEST_ENV
    ? Labels.environment.test
    : IS_DEVELOPMENT_ENV
    ? Labels.environment.dev
    : Labels.environment.lab;
}

console.log('%c' + ENVIRONMENT, 'color: #D93964');
