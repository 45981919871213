import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SliceBaseState } from 'helpers/types';
import { http } from './fetcher';
import { RootState } from 'store/store';
import { isArray, omit } from 'helpers/utils';
import { UserResponse } from 'interfaces/interfaces';
import { ActivitiResponseType } from 'interfaces/ActivitiResponseType';

type UserLookupData = Omit<UserResponse, 'id'>;
export type UserDetailLookup = Record<string, UserLookupData>;

interface UserDetails extends SliceBaseState {
  lookup: UserDetailLookup;
  list: UserResponse[];
}

const initialState: UserDetails = {
  status: 'idle',
  lookup: {},
  list: [],
};

export const fetchUserDetailsAsync = createAsyncThunk('userDetails/fetch', async () => {
  return await http.get<ActivitiResponseType<UserResponse>>(
    'users?size=1000',
    undefined,
    'identity'
  );
});

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload.success && isArray(action.payload.data.data)) {
          state.list = action.payload.data.data;
          state.lookup = action.payload.data.data.reduce<UserDetailLookup>((acc, user) => {
            acc[user.id] = omit(user, 'id');
            return acc;
          }, {});
        }
      })
      .addCase(fetchUserDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default userDetailsSlice.reducer;

export const selectUserDetailsList = (state: RootState) => {
  return state.userDetails.list;
};

export const selectUserDetailsLookup = (state: RootState) => {
  return state.userDetails.lookup;
};

export const selectUserDetails = (state: RootState) => (id: string | null) => {
  return id ? state.userDetails.lookup[id] : undefined;
};
