import FAIcon, { FASpinnerIcon } from 'features/components/atoms/icon/FAIcon';
import { DebouncedComponent } from 'features/components/molecules/DebouncedComponent';
import Modal, { ModalProps } from './Modal';
import Button from '../../atoms/Button';
import { CSSProperties, useEffect, useState } from 'react';
import { Dict } from 'helpers/types';
import useMedia from 'helpers/hooks/useMedia';
import { mediaQueries } from 'helpers/mediaQueries';
import labels from 'configs/labels';
import useRunOnce from 'helpers/hooks/useRunOnce';
import { EventDispatcher } from 'models/EventDispatcher';
import { isDefined } from 'helpers/utils';

interface ModalSpinnerProps extends Omit<ModalProps, 'children' | 'showModal'> {
  showSpinner: boolean;
}

export const ModalSpinner = (props: ModalSpinnerProps) => {
  const [overrideShow, setOverrideShow] = useState(true);
  const showSpinner = overrideShow && props.showSpinner;

  useRunOnce(() => {
    EventDispatcher.subscribe('ON_EXCEPTION', () => setOverrideShow(false));

    return () => {
      EventDispatcher.unsubscribe('ON_EXCEPTION', () => setOverrideShow(false));
    };
  });

  return (
    <DebouncedComponent show={showSpinner}>
      <Modal
        showModal={showSpinner}
        showCloseButton={false}
        contentStyle={{ backgroundColor: 'unset', padding: 0, top: 80, overflowY: 'unset' }}
      >
        <FASpinnerIcon />
      </Modal>
    </DebouncedComponent>
  );
};

const modalBodyStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'flex-start',
};

const btnTexts: Dict = {
  true: 'Dölj teknisk info',
  false: 'Visa teknisk info',
};

interface ModalErrorProps extends Omit<ModalProps, 'children' | 'showModal' | 'showError'> {
  errorMessage?: string;
  stack?: string;
  clearError: () => void;
}

interface ModalMessageProps extends Omit<ModalProps, 'children' | 'showModal' | 'showError'> {
  title: string;
  message: string;
  onClicked: () => void;
  note?: string;
  onCancel?: () => void;
  okBtnText?: string;
  okBtnType?: 'btn-primary' | 'btn-secondary' | 'btn-danger' | 'btn-warning';
  cancelBtnText?: string;
  cancelBtnType?: 'btn-primary' | 'btn-secondary' | 'btn-danger' | 'btn-warning';
}

const modalContentStyle: CSSProperties = {
  padding: 0,
  overflowX: 'clip',
  overflowY: 'auto',
};

export const ModalError = (props: ModalErrorProps) => {
  const hasError = isDefined(props.errorMessage);
  const [showModal, setShowModal] = useState(true);
  const [showStack, setShowstack] = useState(false);
  const [showReload, setShowReload] = useState(false);
  const [showLocalErrorMessage, setShowLocalErrorMessage] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [buttonText, setButtonText] = useState(btnTexts[showStack.toString()]);
  const isSmallDevice = useMedia(mediaQueries.bpMax600);

  const show = hasError && showModal;

  useEffect(() => {
    if (hasError) {
      setShowModal(true);
      EventDispatcher.dispatch('ON_EXCEPTION');
      if (props.errorMessage && props.errorMessage.toLowerCase().includes('failed to fetch')) {
        setShowReload(true);
      }
      if (props.errorMessage && props.errorMessage.toLowerCase().includes('chunkloaderror')) {
        setShowLocalErrorMessage(true);
        setLocalErrorMessage(labels.chunkloadErrorMessage);
      }
      if (props.errorMessage && props.errorMessage.toLowerCase().includes('401')) {
        setShowLocalErrorMessage(true);
        setLocalErrorMessage(labels.unauthorizedErrorMessage);
        setShowReload(true);
      }
      if (
        props.errorMessage &&
        props.errorMessage.toLowerCase().includes('updated by another transaction concurrently')
      ) {
        setShowLocalErrorMessage(true);
        setLocalErrorMessage(labels.concurrentUpdateErrorMessage);
      }
    }
  }, [hasError]);

  const minWidth = isSmallDevice ? 'calc(100vw - 60px)' : 550;

  const handleClick = () => {
    const newValue = !showStack;
    setShowstack(newValue);
    setButtonText(btnTexts[newValue.toString()]);
  };

  return (
    <Modal
      showModal={show}
      showCloseButton={false}
      containerStyle={{ zIndex: 10000 }}
      contentStyle={{ ...modalContentStyle, minWidth }}
    >
      <div className="modal-header">
        <h4 className="modal-title">{labels.errorModalTitle}</h4>
      </div>
      <div className="modal-body" style={modalBodyStyle}>
        <b>
          {!showReload && !showLocalErrorMessage && props.errorMessage}
          {showLocalErrorMessage && localErrorMessage}
          {showReload && !showLocalErrorMessage && labels.reloadMessage}
        </b>
        <TechnicalInfo
          stack={props.stack}
          buttonText={buttonText}
          handleClick={handleClick}
          showStack={showStack}
        />
      </div>
      <div className="modal-footer">
        <div className="row text-end">
          <div className="col">
            {!showReload && (
              <Button
                text={labels.cancel}
                btnType="btn-primary"
                onClick={() => {
                  props.clearError();
                  setShowModal(false);
                }}
              />
            )}
            {showReload && (
              <Button
                text={labels.reload}
                btnType="btn-primary"
                onClick={() => {
                  props.clearError();
                  setShowModal(false);
                  window.location.reload();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface TechnicalInfoProps {
  stack?: string;
  buttonText: string;
  handleClick: () => void;
  showStack: boolean;
}

const rowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 30,
};

const iconStyle: CSSProperties = {
  cursor: 'pointer',
};

const TechnicalInfo = ({ stack, buttonText, handleClick, showStack }: TechnicalInfoProps) => {
  if (stack === undefined || stack.length === 0) return null;

  return (
    <>
      <div style={rowStyle}>
        <Button text={buttonText} btnType="btn-primary" onClick={handleClick} />
        {showStack && (
          <FAIcon
            iconName="faClipboard"
            size="xl"
            color="var(--color-primary)"
            style={iconStyle}
            title={labels.copyErrorToClipboard}
            onClick={() => navigator.clipboard.writeText(stack)}
          />
        )}
      </div>
      <code>{showStack && stack}</code>
    </>
  );
};

export const ModalMessage = (props: ModalMessageProps) => {
  const [showModal, setShowModal] = useState(true);
  const isSmallDevice = useMedia(mediaQueries.bpMax600);

  const minWidth = isSmallDevice ? 'calc(100vw - 60px)' : 550;

  return (
    <Modal
      showModal={showModal}
      showCloseButton={false}
      containerStyle={{ zIndex: 10000 }}
      contentStyle={{ ...modalContentStyle, minWidth }}
    >
      <div className="modal-header">
        <h4 className="modal-title">{props.title}</h4>
      </div>
      <div className="modal-body" style={modalBodyStyle}>
        <b>{props.message}</b>
        {props.note && (
          <div style={{ maxWidth: '450px', wordWrap: 'break-word' }}>{props.note}</div>
        )}
      </div>
      <div className="modal-footer">
        <div className="row text-end">
          {props.onCancel && (
            <div className="col">
              <Button
                text={props.cancelBtnText || labels.cancel}
                btnType={props.cancelBtnType || 'btn-primary'}
                onClick={() => {
                  props.onCancel && props.onCancel();
                  setShowModal(false);
                }}
              />
            </div>
          )}
          <div className="col">
            <Button
              text={props.okBtnText || labels.ok}
              btnType={props.okBtnType || 'btn-primary'}
              onClick={() => {
                props.onClicked();
                setShowModal(false);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
