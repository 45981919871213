import { FACloseIcon } from 'features/components/atoms/icon/FAIcon';
import { CSSProperties, useState } from 'react';
import './modal.css';
import useMedia from 'helpers/hooks/useMedia';
import { mediaQueries } from 'helpers/mediaQueries';

export interface ModalProps {
  children: React.ReactNode;
  showModal: boolean;
  showCloseButton?: boolean;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  onCloseBtnClick?: () => void;
}

export default function Modal({
  children,
  onCloseBtnClick,
  containerStyle = {},
  contentStyle,
  showModal,
  showCloseButton = true,
}: ModalProps) {
  const [isVisible, setIsVisible] = useState<boolean>();
  const isSmallDevice = useMedia(mediaQueries.bpMax600);

  const show = showModal && (isVisible === undefined || isVisible);
  if (!show) return null;

  const handleCloseBtnClick = () => {
    if (onCloseBtnClick) {
      onCloseBtnClick();
    } else {
      setIsVisible(false);
    }
  };

  const containerCls = show ? 'diremo-modal-container' : '';
  const minWidth: CSSProperties = isSmallDevice
    ? { padding: '0 12px' }
    : { width: 'calc(100vw - 16px)' };

  return (
    <div className={containerCls} style={{ ...containerStyle, ...minWidth }}>
      <span className="diremo-modal-content" style={contentStyle}>
        <div className="container">
          <CloseBtnRow
            showCloseButton={showCloseButton}
            handleCloseBtnClick={handleCloseBtnClick}
          />
          <div className="row">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </span>
    </div>
  );
}

interface CloseBtnRowProps extends Pick<ModalProps, 'showCloseButton'> {
  handleCloseBtnClick: () => void;
}

const CloseBtnRow = ({ showCloseButton, handleCloseBtnClick }: CloseBtnRowProps) => {
  if (!showCloseButton) return null;

  return (
    <div className="row">
      <div className="col-12 diremo-modal-close-button">
        <FACloseIcon onClick={handleCloseBtnClick} />
      </div>
    </div>
  );
};
