import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableState } from '@tanstack/react-table';
import { SliceBaseState } from 'helpers/types';
import { AppThunk, RootState } from 'store/store';
import { selectCurrentRoute } from '../navigation/navigationSlice';

type Data = {
  searchTerm: string;
  endpoint: string;
  tableState: TableState;
  historyIdx: number;
};

interface DiremoTableProps {
  route: string;
  data: Partial<Data>;
}

export interface DiremoTablePropsState extends SliceBaseState {
  props: Record<string, Partial<Data>>;
}

const initialState: DiremoTablePropsState = {
  status: 'idle',
  props: {},
};

const diremoTablePropsSlice = createSlice({
  name: 'diremoTableProps',
  initialState,
  reducers: {
    setDiremoTablePropsOnRoute: (state, action: PayloadAction<DiremoTableProps>) => {
      const { route, data } = action.payload;
      const existingData = state.props[route] ?? {};

      state.props[route] = { ...existingData, ...data, historyIdx: history.length - 1 };
    },
  },
});

export const setDiremoTableProps =
  (data: Partial<Data>): AppThunk =>
  (dispatch, getState) => {
    const currentRoute = selectCurrentRoute(getState());
    dispatch(
      diremoTablePropsSlice.actions.setDiremoTablePropsOnRoute({ route: currentRoute, data })
    );
  };

export const { setDiremoTablePropsOnRoute } = diremoTablePropsSlice.actions;

export default diremoTablePropsSlice.reducer;

export const selectDiremoTableProps = (state: RootState) =>
  state.diremoTableProps.props[state.navigation.currentRoute] ?? {};
