import { useState, useEffect } from 'react';
import { MediaQuery, mediaQueries } from '../mediaQueries';

export default function useMedia(breakPoint: MediaQuery, additionalBreakPoint?: MediaQuery) {
  const query = additionalBreakPoint ? `${breakPoint} and ${additionalBreakPoint}` : breakPoint;

  const [matches, setMatches] = useState(() => matchMedia(query).matches ?? true);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
}

export const useExtraSmallDevice = () => useMedia(mediaQueries.bpMax600);

export const useSmallDevice = () => useMedia(mediaQueries.bpMin601, mediaQueries.bpMax960);

export const useMediumDevice = () => useMedia(mediaQueries.bpMin991, mediaQueries.bpMax1280);

export const useLargeDevice = () => useMedia(mediaQueries.bpMin1281, mediaQueries.bpMax1920);

export const useExtraLargeDevice = () => useMedia(mediaQueries.bpMin1921);
