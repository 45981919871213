import { BSButtonColor } from 'helpers/types';
import React from 'react';
import { AfbFAIcon, FAIconName } from 'features/components/atoms/icon/FAIcon';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  btnType?: BSButtonColor;
  iconName?: FAIconName;
}

export default function Button({
  ref,
  text,
  onClick,
  disabled = false,
  btnType,
  className,
  style,
  iconName,
}: ButtonProps) {
  const clsName = `shadow-none ${className ?? 'btn ' + btnType}`;
  const stl = { ...{ borderRadius: '0.25em' }, ...style };

  return (
    <button ref={ref} onClick={onClick} className={clsName} disabled={disabled} style={stl}>
      {iconName && <AfbFAIcon iconName={iconName} size="xl" style={{ paddingRight: '5px' }} />}
      {text}
    </button>
  );
}
