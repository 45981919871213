import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceBaseState } from 'helpers/types';
import { AppThunk, RootState } from 'store/store';
import { selectCurrentRoute } from './navigation/navigationSlice';

type Data<T> = Record<string, T>;

interface PayloadData<T> {
  route: string;
  data: T;
}

interface GenericState<T> extends SliceBaseState {
  data: Data<T>;
}

const initialState: GenericState<Array<unknown>> = {
  status: 'idle',
  data: {},
};

const genericSlice = createSlice({
  name: 'generic',
  initialState,
  reducers: {
    setGenericDataOnRoute: <T>(state: GenericState<T>, action: PayloadAction<PayloadData<T>>) => {
      const { route, data } = action.payload;
      state.data[route] = data;
    },
  },
});

export const setGenericData =
  <T>(data: T): AppThunk =>
  (dispatch, getState) => {
    const currentRoute = selectCurrentRoute(getState());
    dispatch(setGenericDataOnRoute({ route: currentRoute, data }));
  };

export const { setGenericDataOnRoute } = genericSlice.actions;

export default genericSlice.reducer;

export const selectGenericData = <T>(state: RootState) => {
  const currentRoute = state.navigation.currentRoute;

  return state.generic.data[currentRoute] as T;
};
