import { DurationMinutes } from 'helpers/types';
import { setAppStatus } from 'slices/appStatusSlice';
import { StoreDispatch } from 'store/store';
import { Context, FetcherFn, http, isSuccessResponse } from 'slices/fetcher';

interface Cache<T = unknown> {
  ts: number;
  data: Promise<T>;
}

export default class HttpFetcherCache {
  private dispatch: StoreDispatch;
  private token = '';
  private cache: Record<string, Cache> = {};
  private msMaxAge = 600_000;

  constructor(dispatch: StoreDispatch) {
    this.dispatch = dispatch;
  }

  setToken(token: string) {
    this.token = token;
  }

  private isStaleData<T>(cache: Cache<T>, maxAge?: DurationMinutes) {
    if (maxAge && maxAge < 0) return false;
    if (maxAge === 0) return true;

    const msMaxAge = maxAge === undefined ? this.msMaxAge : maxAge * 60_000;
    return Date.now() - cache.ts >= msMaxAge;
  }

  async get<EndpointReturnType, ReducerReturnType>(
    endpoint: string,
    reducer?: (d: EndpointReturnType) => ReducerReturnType,
    maxAge?: DurationMinutes,
    context?: Context,
    signal?: AbortSignal
  ) {
    return this.fetcherFunction<EndpointReturnType, ReducerReturnType>(
      http.get,
      endpoint,
      reducer,
      maxAge,
      context,
      signal
    );
  }

  evict(endpoint: string) {
    delete this.cache[endpoint];
  }

  async post<EndpointReturnType, ReducerReturnType>(
    endpoint: string,
    reducer?: (d: EndpointReturnType) => ReducerReturnType,
    maxAge?: DurationMinutes,
    context?: Context,
    signal?: AbortSignal
  ) {
    return this.fetcherFunction<EndpointReturnType, ReducerReturnType>(
      http.post,
      endpoint,
      reducer,
      maxAge,
      context,
      signal
    );
  }

  private fetcherFunction<EndpointReturnType, ReducerReturnType>(
    fetcherFn: FetcherFn,
    endpoint: string,
    reducer?: (d: EndpointReturnType) => ReducerReturnType,
    maxAge?: DurationMinutes,
    context?: Context,
    signal?: AbortSignal
  ) {
    const cache = this.cache[endpoint] as Cache<ReducerReturnType>;

    if (cache === undefined || this.isStaleData(cache, maxAge)) {
      this.dispatch(setAppStatus('loading'));

      const promise = fetcherFn<EndpointReturnType>(
        endpoint,
        { token: this.token, signal },
        context
      ).then((result) => {
        if (isSuccessResponse(result)) {
          const data = reducer
            ? reducer(result.data)
            : (result.data as unknown as ReducerReturnType);

          this.dispatch(setAppStatus('idle'));
          return data;
        }

        if (result.data.ignoreThisError === true) return;

        this.dispatch(setAppStatus('failed'));
        throw new Error(JSON.stringify(result.data));
      });

      this.cache[endpoint] = { ts: Date.now(), data: promise };
    }

    return this.cache[endpoint].data as ReducerReturnType;
  }
}
