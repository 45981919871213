import { keycloakConfig, keycloakInitOptions } from 'configs/keycloak-config';
import Keycloak from 'keycloak-js';
import { KeycloakProps, authenticationFailed, logIn } from 'slices/navigation/navigationSlice';
import { StoreDispatch, initReduxState } from 'store/store';

export type Tokens = Pick<KeycloakProps, 'token' | 'refreshToken' | 'idToken'>;

export default class KC {
  private kc: Keycloak;

  constructor(
    private readonly setKeycloakProps: (keycloakProps: KeycloakProps) => void,
    private readonly dispatch: StoreDispatch,
    tokens?: Tokens
  ) {
    this.kc = new Keycloak(keycloakConfig);

    if (tokens) this.init(tokens);

    this.setEvents();
  }

  setEvents() {
    const { kc, dispatch, onAuthSuccess } = this;
    // On first successful login
    kc.onAuthSuccess = () => onAuthSuccess(true);

    kc.onAuthError = (err) => {
      dispatch(authenticationFailed());
      // eslint-disable-next-line
      console.error('Authentication Failed', err);
    };

    // When token has been successfully refreshed
    kc.onAuthRefreshSuccess = () => onAuthSuccess(false);

    kc.onAuthRefreshError = () => {
      const isOffline = sessionStorage.getItem('offline') === 'true';
      if (isOffline) {
        console.log('Token Refresh Failed, ignore due to off-line mode.');
      } else {
        // eslint-disable-next-line
        dispatch(authenticationFailed());
        console.error('Token Refresh Failed');
      }
    };

    kc.onTokenExpired = () => {
      kc.updateToken();
    };
  }

  init(tokens?: Tokens) {
    this.kc.init({ ...keycloakInitOptions, ...tokens });
  }

  private onAuthSuccess = (initRedux: boolean) => {
    this.kc.loadUserProfile().then(() => {
      const keycloakProps = this.keycloakProps;

      this.setKeycloakProps(keycloakProps);
      this.dispatch(logIn(keycloakProps));

      if (initRedux) initReduxState();
    });
  };

  get token() {
    return this.kc.token ?? '';
  }

  get bearerToken() {
    return this.kc.token ? `Bearer ${this.kc.token}` : '';
  }

  get keycloakProps(): KeycloakProps {
    return {
      isAuthenticated: this.kc.authenticated ?? false,
      profile: this.kc.profile,
      token: this.kc.token,
      tokenParsed: this.kc.tokenParsed,
      refreshToken: this.kc.refreshToken,
      idToken: this.kc.idToken,
    };
  }

  get profile() {
    return this.kc.profile;
  }

  get username() {
    return this.kc.profile?.username ?? '';
  }

  logout() {
    this.kc.logout();
  }
}
